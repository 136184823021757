<template>
  <div id="information">
    <div class="hidden-sm-and-down">
      <PageHeader/>
    </div>
    <div class="hidden-md-and-up">
      <m-top/>
    </div>

    <div class="hidden-xs-only">
      <el-row type="flex" justify="center">
        <el-col :md="20" :lg="18" :xl="15">
          <div style="width: 100%; max-width: 1284px; margin:0 auto">
            <div class="page-body">
              <div class="main-title">お知らせ一覧</div>
              <div class="detail">
                <el-row type="flex">

                  <!-- 左侧各个显示内容 -->
                  <el-col :span="20">
                    <div v-if="showList">
                      <div v-for="item in leftList" class="info" @click="getInfo(item)">
                        <div class="title">{{item.title}}</div>
                        <div class="left-separator"/>
                        <div class="time">{{item.createTime}}</div>
                        <div class="content">{{item.content}}</div>
                        <div class="moreInfo"><el-link :underline="false">詳細</el-link></div>
                      </div>
                    </div>
                    <div v-else class="info-item">
                      <div class="title">{{leftInfo.title}}</div>
                      <div class="left-separator"></div>
                      <div class="time">{{leftInfo.createTime}}</div>
                      <div class="content">{{leftInfo.content}}</div>
                    </div>
                  </el-col>

                  <!-- 右侧时间列表 -->
                  <el-col :span="8">
                    <div class="back-number">
                      <div class="back-title">
                        <span class="title-ja">過去のお知らせ</span>
                        <span class="title-en">Back Number</span>
                      </div>
                      <div class="right-separator"/>
                      <div v-for="item in rightMonthList" class="month">
                        <el-link :underline="false" @click="selectMonth(item)">{{ item }}</el-link>
                      </div>
                    </div>
                  </el-col>
                </el-row>

              </div>
            </div>
          </div>

        </el-col>
      </el-row>

      <PageFooter clinicName="築地" pathName="tsukiji"/>

    </div>

    <div class="hidden-sm-and-up">
      
      <div class="m-title">お知らせ一覧</div>

      <div>
        <div class="m-item" v-for="item in leftList" @click="goTo(item.id)">
          <div class="m-subtitle">{{item.title}}</div>
          <div class="m-time">{{item.createTime}}</div>
          <div class="m-content">{{item.content}}</div>
        </div>
      </div>

      <m-access/>

      <m-footer/>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import moment from "moment";
import MTop from "../components/m/MTop";
import MFooter from "../components/m/MFooter";
import MAccess from "../components/m/MAccess";
export default {
  name: "Notice",
  components: {MAccess, MFooter, MTop, PageFooter, PageHeader},
  data() {
    return {
      // 左侧是显示列表，还是お知らせ内容。。默认显示列表
      showList: true,
      allInformation: [],
      leftList: [],
      // 左侧显示的お知らせ
      leftInfo: null,
      rightMonthList: [],
    }
  },
  created() {
    axios.get(`${this.GLOBAL.host}/t/info/all`).then(resp => {
      // 按时间排序
      resp.data.sort((a, b) => {
        let t1 = new Date(a.createTime)
        let t2 = new Date(b.createTime)
        return t2.getTime() - t1.getTime()
      })
      resp.data.map(i => {
        i.createTime = moment(i.createTime).format('YYYY.MM.DD')
      })
      this.allInformation = resp.data
      this.allLeftList(this.allInformation)

      //根据读取的数据，获取右侧所有月份
      this.getMonthList(this.allInformation)
    })
    if (this.$route.query.id) {
      let id = this.$route.query.id
      axios.get(`${this.GLOBAL.host}/t/info/get/${id}`).then(resp => {
        resp.data.createTime = moment(resp.data.createTime).format('YYYY.MM.DD')
        this.leftInfo = resp.data
        this.showList = this.$route.query.showList === 'true'
      })
    }
  },
  methods: {
    getMonthList(allInformation) {
      let monthList = allInformation.map(i => i.createTime.slice(0, 7))
      monthList.splice(0, 0, 'すべて')
      this.rightMonthList = Array.from(new Set(monthList))
    },
    allLeftList(allInformation) {
      this.leftList = allInformation
    },
    selectMonth(item) {
      this.showList = true
      if (item === 'すべて') {
        this.allLeftList(this.allInformation)
      } else {
        let selectInformation = this.allInformation.filter(i => i.createTime.slice(0, 7) === item)
        this.leftList = selectInformation
      }
    },
    goTo(id) {
      this.$router.push({
        path: '/infoitem',
        query: {id}
      })
    },
    getInfo(item) {
      this.showList = false
      this.leftInfo = item
    }
  }
}
</script>

<style scoped>
/* PC */
.page-body .main-title {
  color: #535353;
  font-size: 24px;
}
.detail {
  background-color: #ffffff;
}
/* お知らせ一覧 */
.detail /deep/ .el-row .el-col:nth-child(1) {
  margin-top: 30px;
}
.left-separator {
  border-top: 2px solid #62b6e2;
  margin: 10px 0 20px;
}
.info {
  box-shadow: 0 2px 4px #ccc;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 30px;
}
.info .title, .info-item .title {
  font-size: 16px;
  font-weight: bold;
  color: #62b6e2;
}
.info .time, .info-item .time {
  font-size: 14px;
  color: #9e9e9e;
  margin-bottom: 20px;
  padding: 0 10px;
}
.info .content, .info-item .content {
  font-size: 14px;
  line-height: 2;
  padding: 0 10px;
  white-space: pre-line; /*显示回车*/

}
.info .content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}
.info .moreInfo .el-link {
  color: #9e9e9e;
  text-align: right;
  margin-top: 20px;
  padding: 0 10px;
}
.info .moreInfo:hover .el-link {
  color: #62b6e2;
}
/*過去のお知らせ*/
.back-number {
  margin: 30px;
}
.back-title .title-ja {
  font-size: 16px;
  color: #535353;
}
.back-title .title-en {
  font-size: 12px;
  color: #aaa;
  margin-left: 13px;
}
.right-separator {
  border-top: 1px dotted #CCCCCC;
  margin: 20px 0;
}
.month a {
  color: #9e9e9e;
  margin: 7px 0;
}
.month a:hover {
  color: #62b6e2;
}
/*中间分割线*/
/* .detail /deep/ .el-row .el-col:nth-child(1) {
  margin: 40px;
  padding-right: 40px;
  border-right: 1px solid #333;
}
.info-list {
  margin-bottom: 40px;
  cursor: pointer;
}
.info-list .title, .info-item .title {
  font-size: 18px;
  color: #62b6e2;
  font-weight: bold;
}
.left-separator {
  width: 100%;
  border-top: 3px solid #62b6e2;
  margin: 15px 0;
}
.info-list .time, .info-item .time {
  font-size: 14px;
  color: #62b6e2;
  margin-bottom: 20px;
}
.info-list .content, .info-item .content {
  font-size: 16px;
  line-height: 1.5;
  padding-bottom: 30px;
  white-space: pre-line;
}
.info-list .content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}
.moreInfo {
  text-align: right;
  margin-top: 1rem;
  margin-right: 1rem;
} */


/*右侧目录*/
/* .back-title {
  font-weight: bold;
  margin-top: 40px;
}
.back-title .title-ja {
  font-size: 16px;
  color: #333;
}
.back-title .title-en {
  font-size: 13px;
  color: #aaa;
  margin-left: 13px;
  letter-spacing: 4px;
}
.right-separator {
  width: 80%;
  border-top: 2px dotted #333;
  margin: 20px 0;
}
.month a {
  color: rgba(98, 182, 226, 0.99);
  margin: 7px 0;
}
.month a:hover {
  color: rgba(98, 182, 226, 0.7);
} */

/*手机端*/
.m-title {
  text-align: center;
  color: #333;
  font-size: 24px;
  font-weight: bold;
  margin: 2rem;
}
.m-item {
  background-color: #FFFFFF;
  padding: 1rem;
  border-bottom: 2px solid #62b6e2;
}
.m-item>div {
  margin: 10px 0;
}
.m-time {
  color: #333;
  font-size: 12px;
}
.m-subtitle {
  color: #62b6e2;
  font-size: 18px;
  font-weight: bold;

  /* 只显示一行 */
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow : hidden;
  text-overflow: ellipsis;
}
.m-content {
  color: #333;
  font-size: 14px;
  line-height: 1.5rem;

  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow : hidden;
  text-overflow: ellipsis;
}
</style>
